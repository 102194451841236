import { GenericAppointmentHeaderCard } from "./GenericAppointmentHeaderCard";
import { StatusContainer } from "./status-container";
import React, { useCallback, useState } from "react";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Stage } from "../../helpers/get-settings";
import { GenericAppointmentDetailsFlyout } from "./GenericAppointmentDetailsFlyout";
import { NHE_EVENT_TYPE } from "../../config/appConfigConstants";
import { ChecklistPantherKey } from "../../utility/types/translations";
import { useGetDTRetakeAppointment } from "../../hooks/apis/useGetDTRetakeAppointment";

export const DTRetakeAppointmentHeader = ({
  sfRequiredLanguage,
  stage,
}: {
  sfRequiredLanguage: string | undefined;
  stage?: Stage;
}) => {
  const [isDetailsViewOpen, setIsDetailsViewOpen] = useState(false);

  const { DTRetakeAppointmentServiceURL } = useUrlBuilder();
  const { dtRetakeAppointmentDetails, isEligibleForDTRetake } = useGetDTRetakeAppointment();

  return (
    <>
      <StatusContainer
        data-testid="page-header-drug-test-retake-available"
        largeViewPortImage={require("../../assets/images/calendar-large-viewport.png").default}
        smallViewPortImage={require("../../assets/images/calendar-small-viewport.png").default}
      >
        <GenericAppointmentHeaderCard
          rescheduleAppointmentURL={DTRetakeAppointmentServiceURL}
          countDownMsgKey="Checklist-DT-AppointmentCard-Countdown"
          countDownMsgTodayKey="Checklist-DT-AppointmentCard-CountdownToday"
          appointmentTitleKey="Checklist-Header-DT-Appointment-Title"
          calendarFileNameKey="Checklist-Header-DT-Appointment-Title"
          isEligibleForReschedule={isEligibleForDTRetake}
          appointmentV2Details={dtRetakeAppointmentDetails as AppointmentInfo}
          onlyOneAppointment={true}
          stage={stage}
          sfRequiredLanguage={sfRequiredLanguage}
          onViewDetailsClick={() => setIsDetailsViewOpen(true)}
        />
      </StatusContainer>
      <GenericAppointmentDetailsFlyout
        appointmentType={NHE_EVENT_TYPE.DT}
        rescheduleAppointmentURL={DTRetakeAppointmentServiceURL}
        appointmentTitleKey="Checklist-Header-DT-Appointment-Title"
        isEligibleForReschedule={isEligibleForDTRetake}
        appointmentInfo={dtRetakeAppointmentDetails as AppointmentInfo}
        isOpen={isDetailsViewOpen}
        closeFlyout={() => setIsDetailsViewOpen(false)}
      />
    </>
  );
};
