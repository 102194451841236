import { useParams } from "react-router-dom";
import { isDragonstone, isSalesforceApplication } from "../helpers/url-params";
import { getAdobeEventTextName } from "../helpers/utils";
import * as adobeAnalytics from "../utility/adobe-analytics";
import { useGetJobDetails } from "./apis/useGetJobDetails";
import { useGetApplicationManageData } from "./apis/useGetApplicationManageData";
import { useGetCandidateData } from "./apis/useGetCandidateData";
import { NHE_EVENT_TYPE } from "../config/appConfigConstants";

type useSendJobCardAnalyticEvent = () => {
  sendNewHireVideoAnalyticsEvent: (eventName: string) => Promise<void>;
  sendFirstDayChangeAnalyticsEvent: (eventName: string) => Promise<void>;
  sendBadgePhotoPageAnalyticsEvent: (eventName: string) => Promise<void>;
  sendBadgePhotoEventAnalyticsEvent: (eventName: string) => Promise<void>;
  sendWithdrawApplicationAnalyticsEvent: (eventName: string, withdrawReason: string) => Promise<void>;
  sendDocumentsAnalyticsEvent: (eventName: string, option?: any, documentType?: string) => Promise<void>;
  sendRescheduleClickEvent: (type: NHE_EVENT_TYPE) => void;
};

export const useSendAnalyticEvent: useSendJobCardAnalyticEvent = () => {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();

  const jobDetails = useGetJobDetails();
  const applicationManageData = useGetApplicationManageData();
  const candidateData = useGetCandidateData();

  const sendFirstDayChangeAnalyticsEvent = async (eventName: string) => {
    if (!isSalesforceApplication(applicationId)) {
      await adobeAnalytics.addEventMetric(
        window,
        applicationId,
        eventName,
        candidateData,
        applicationManageData,
        jobDetails,
        {
          dragonstoneSchedule: {
            scheduleID: jobDetails?.scheduleId,
            jobID: requisitionId,
          },
          geocluster: {
            ID: jobDetails?.geoClusterId,
          },
        }
      );
    }
  };

  const sendWithdrawApplicationAnalyticsEvent = async (eventName: string, withdrawReason: string) => {
    await adobeAnalytics.addEventMetric(
      window,
      applicationId,
      eventName,
      candidateData,
      applicationManageData,
      jobDetails,
      {
        dragonstoneSchedule: {
          scheduleID: jobDetails?.scheduleId,
        },
        dragonstoneJob: {
          jobID: jobDetails?.jobId,
        },
        geocluster: {
          ID: jobDetails?.geoClusterId,
        },
        withdraw: {
          reason: withdrawReason,
        },
      }
    );
  };

  const sendDocumentsAnalyticsEvent = async (eventName: string, option?: any) => {
    await adobeAnalytics.addEventMetric(
      window,
      applicationId,
      eventName,
      candidateData,
      applicationManageData,
      jobDetails,
      {
        dragonstoneSchedule: {
          scheduleID: jobDetails?.scheduleId,
        },
        dragonstoneJob: {
          jobID: jobDetails?.jobId,
        },
        geocluster: {
          ID: jobDetails?.geoClusterId,
        },
        text: getAdobeEventTextName(eventName, option),
        option: option,
      }
    );
  };

  const sendRescheduleClickEvent = (type: NHE_EVENT_TYPE) => {
    let lowercaseAppointment = "unknown";
    switch (type) {
      case NHE_EVENT_TYPE.DT:
        lowercaseAppointment = "drug test";
        break;
      case NHE_EVENT_TYPE.NHE:
        lowercaseAppointment = "new hire event";
        break;
      case NHE_EVENT_TYPE.RTW:
        lowercaseAppointment = "right to work";
        break;
    }

    adobeAnalytics.addEventMetric(
      window,
      applicationId,
      `reschedule link click ${lowercaseAppointment}`,
      candidateData,
      applicationManageData,
      jobDetails,
      {
        dragonstoneSchedule: {
          scheduleID: jobDetails?.scheduleId,
        },
        dragonstoneJob: {
          jobID: jobDetails?.jobId,
        },
        geocluster: {
          ID: jobDetails?.geoClusterId,
        },
      }
    );
  };

  const sendNewHireVideoAnalyticsEvent = async (eventName: string) => {
    await adobeAnalytics.addPageLoadMetric(
      candidateData,
      applicationManageData,
      jobDetails,
      window,
      applicationId,
      eventName,
      {
        referrer: adobeAnalytics.interpretReferrer(document.referrer),
        jobId: requisitionId,
        isDragonstone: isDragonstone(requisitionId),
      }
    );
  };

  const sendBadgePhotoPageAnalyticsEvent = async (eventName: string) => {
    await adobeAnalytics.addPageLoadMetric(
      candidateData,
      applicationManageData,
      jobDetails,
      window,
      applicationId,
      eventName,
      {
        referrer: adobeAnalytics.interpretReferrer(document.referrer),
      }
    );
  };

  const sendBadgePhotoEventAnalyticsEvent = async (eventName: string) => {
    await adobeAnalytics.addEventMetric(
      window,
      applicationId,
      eventName,
      candidateData,
      applicationManageData,
      jobDetails,
      {
        referrer: adobeAnalytics.interpretReferrer(document.referrer),
      }
    );
  };

  return {
    sendNewHireVideoAnalyticsEvent,
    sendFirstDayChangeAnalyticsEvent,
    sendWithdrawApplicationAnalyticsEvent,
    sendDocumentsAnalyticsEvent,
    sendRescheduleClickEvent,
    sendBadgePhotoPageAnalyticsEvent,
    sendBadgePhotoEventAnalyticsEvent,
  };
};
