import { GenericAppointmentHeaderCard } from "./GenericAppointmentHeaderCard";
import { StatusContainer } from "./status-container";
import React, { useCallback, useState } from "react";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getFeatureFlag } from "../../reduxStore/slices/configSlice";
import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { Stage } from "../../helpers/get-settings";
import { useGetDTAppointment } from "../../hooks/apis/useGetDTAppointment";
import { GenericAppointmentDetailsFlyout } from "./GenericAppointmentDetailsFlyout";
import { NHE_EVENT_TYPE } from "../../config/appConfigConstants";
import { ChecklistPantherKey } from "../../utility/types/translations";

export const DTAppointmentHeader = ({
  sfRequiredLanguage,
  stage,
}: {
  sfRequiredLanguage: string | undefined;
  stage?: Stage;
}) => {
  const [isDetailsViewOpen, setIsDetailsViewOpen] = useState(false);

  const { DTAppointmentSelfServiceURL } = useUrlBuilder();
  const { isEligibleForDTAppointmentRescheduling } = useAppSelector(getFeatureFlag);
  const DTInfo = useGetDTAppointment();

  return (
    <>
      <StatusContainer
        data-testid="page-header-drug-test-available"
        largeViewPortImage={require("../../assets/images/calendar-large-viewport.png").default}
        smallViewPortImage={require("../../assets/images/calendar-small-viewport.png").default}
      >
        <GenericAppointmentHeaderCard
          rescheduleAppointmentURL={DTAppointmentSelfServiceURL}
          countDownMsgKey="Checklist-DT-AppointmentCard-Countdown"
          countDownMsgTodayKey="Checklist-DT-AppointmentCard-CountdownToday"
          appointmentTitleKey="Checklist-Header-DT-Appointment-Title"
          calendarFileNameKey="Checklist-Header-DT-Appointment-Title"
          isEligibleForReschedule={isEligibleForDTAppointmentRescheduling}
          appointmentV2Details={DTInfo as AppointmentInfo}
          onlyOneAppointment={true}
          stage={stage}
          sfRequiredLanguage={sfRequiredLanguage}
          onViewDetailsClick={() => setIsDetailsViewOpen(true)}
        />
      </StatusContainer>
      <GenericAppointmentDetailsFlyout
        appointmentType={NHE_EVENT_TYPE.DT}
        rescheduleAppointmentURL={DTAppointmentSelfServiceURL}
        appointmentTitleKey="Checklist-Header-DT-Appointment-Title"
        isEligibleForReschedule={isEligibleForDTAppointmentRescheduling}
        appointmentInfo={DTInfo}
        isOpen={isDetailsViewOpen}
        closeFlyout={() => setIsDetailsViewOpen(false)}
      />
    </>
  );
};
